<template>
  <div id="contacts-page" class="d-flex flex-column h-100">
    <div id="page-header">
      <h1 id="page-title" class="h2">Behandelaar</h1>
      <ul id="page-actions" class="nav">
        <li class="nav-item">
          <a href="#" role="button" class="nav-link" data-toggle="modal" data-target="#addContactModal" aria-label="Verbinding maken">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
          </a>
        </li>
        <li class="nav-item">
          <a href="#" role="button" class="nav-link" data-toggle="modal" data-target="#pageHelpModal" aria-label="Help">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
            </svg>
          </a>
        </li>
      </ul>
    </div>

    <div v-if="!contacts || !contacts.length" class="d-flex h-100">
      <div class="d-flex flex-column w-100 justify-content-center align-items-center">
        <span class="text-black-50 font-weight-medium mb-3">U heeft nog geen behandelaars</span>
        <button role="button" class="btn btn-mnnu-cyan font-weight-normal btn-md" data-toggle="modal" data-target="#addContactModal" aria-label="Verbinding maken">Behandelaar toevoegen</button>
      </div>
    </div>

    <div v-else id="content">
      <div class="mb-4">
        <input type="search" v-model="search" class="form-control border-0" placeholder="Zoeken...">
      </div>

      <div class="row row-cols-1 row-cols-md-3">
        <div v-for="contact in filteredList" :key="contact.contact_id" class="col mb-4">
          <router-link :to="{ name: 'contact', params: { contactId: contact.contact_id }}" class="card mnnu-card h-100">
            <span class="badge badge-danger notification-badge" v-show="(unreadRequests = totalUnreadRequestsByContact(contact)) > 0">{{ unreadRequests }}</span>
            <div class="card-body text-center d-flex flex-column">
              <div class="mb-4 flex-fill">
                <img src="https://via.placeholder.com/50" style="max-width: 90px; height: 90px;">
              </div>
              <div class="card-text flex-fill font-weight-medium">{{ contact.contact_label }}</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <modal id="pageHelpModal" title="Behandelaar">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ullamcorper aliquet nulla a facilisis. Vivamus aliquam ex ut justo suscipit, eu vestibulum justo dictum. Nulla varius, tortor nec tempor pellentesque, erat velit tincidunt libero, eu fringilla erat massa volutpat turpis. Aliquam et nibh massa. Vivamus nulla ipsum, ornare vitae felis ut, fringilla venenatis risus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In sed ornare nisl. Mauris nec nunc quis purus varius aliquet. Integer a pellentesque ligula. Etiam et imperdiet urna.</p>
    </modal>

    <modal id="addContactModal" title="Verbinding met behandelaar maken">
      <form>
        <div class="form-group">
          <label for="token">Unieke code</label>
          <input v-model="token" type="text" class="form-control" id="token" aria-describedby="tokenHelp">
          <small id="tokenHelp" class="form-text text-muted">Vul hier de code in die aan u is verstrekt door uw behandelaar.</small>
        </div>
      </form>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuleren</button>
        <button type="button" class="btn btn-primary" @click="processAddContactForm()">Verbinding maken</button>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Modal from '@/components/ui/Modal'

export default {
  name: 'Contacts',
  components: { Modal },
  data () {
    return {
      search: '',
      token: ''
    }
  },
  computed: {
    ...mapState([
      'contacts'
    ]),
    ...mapGetters([
      'totalUnreadRequestsByContact'
    ]),
    filteredList () {
      return this.contacts.filter(contact => {
        return contact.contact_label.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    ...mapActions([
      'addContact'
    ]),
    processAddContactForm () {
      this.addContact(this.token)
      this.token = ''
      $('#addContactModal').modal('hide')
    }
  }
}
</script>
